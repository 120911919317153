<script setup lang="ts" generic="TSlide extends Record<string, unknown>">
import type { CSSProperties } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { SwiperOptions, Swiper as SwiperClass } from 'swiper/types'
import { Virtual, Keyboard, A11y, Pagination, Navigation } from 'swiper/modules'

defineSlots<{
  'slider-content'(p: { slide: TSlide; slideIndex: number }): any
  navigation(): any
}>()

const emits = defineEmits<{
  'click-on-image': []
  'slide-change-active-index': [number]
  'handle-click-container': [MouseEvent]
}>()

const props = withDefaults(
  defineProps<{
    imageAttr?: { alt: string }
    cover?: boolean
    baseImageStyleObject?: CSSProperties
    className?: string
    pagination?: boolean
    navigation?: boolean
    virtual?: boolean
    slides?: TSlide[]
    swiperClass?: string
    swiperSlideClass?: string
    swiperOptions?: Omit<
      SwiperOptions,
      'url' | 'width' | 'height' | 'userAgent' | 'swipeHandler'
    > & {
      url?: string
      width?: number
      height?: number
      userAgent?: string
      swipeHandler?: boolean
    }
  }>(),
  {
    imageAttr: () => ({
      alt: '',
    }),
    cover: true,
    baseImageStyleObject: () => ({}),
    className: '',
    pagination: false,
    navigation: false,
    virtual: true,
    slides: () => [],
    swiperClass: '',
    swiperSlideClass: '',
    swiperOptions: () => ({}),
  },
)

const virtualCarousel = ref<SwiperClass>()
const swiperIndex = ref(0)

const onSwiper = (swiper: SwiperClass) => {
  virtualCarousel.value = swiper
}

const slideNext = () => {
  virtualCarousel.value?.slideNext()
}
const slidePrev = () => {
  virtualCarousel.value?.slidePrev()
}
const slideTo = (index: number) => {
  virtualCarousel.value?.slideTo(index)
}
const onSlideChange = (swiper: SwiperClass) => {
  swiperIndex.value = swiper.activeIndex
  emits('slide-change-active-index', swiper.activeIndex)
}
const clickOnImage = () => {
  emits('click-on-image')
}
const handleClickContainer = (e: MouseEvent) => {
  emits('handle-click-container', e)
}

defineExpose({ slideTo, slideNext, slidePrev })
</script>

<template>
  <div
    :class="['relative swiper', className]"
    @click.self="handleClickContainer"
  >
    <nuxt-error-boundary @error="() => {}">
      <swiper
        :class="['h-full', swiperClass]"
        :modules="[Virtual, A11y, Pagination, Keyboard, Navigation]"
        :pagination="{
          el: '.swiper-pagination',
          enabled: pagination,
          clickable: true,
        }"
        :navigation="{
          enabled: navigation,
          nextEl: `.${props.className} .swiper--next`,
          prevEl: `.${props.className} .swiper--prev`,
          disabledClass: 'swiper-button-disabled',
        }"
        cache
        v-bind="swiperOptions"
        :virtual="virtual"
        @slideChange="onSlideChange"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(slide, slideIndex) in slides"
          :key="slideIndex"
          :class="swiperSlideClass"
          :virtual-index="slideIndex"
        >
          <slot
            name="slider-content"
            :slide-index="slideIndex + 1"
            :slide="slide"
          >
            <div class="h-full flex justify-center">
              <BaseNuxtImg
                aspect-ratio="16/9"
                :alt="imageAttr.alt"
                :cover="cover"
                :src="slide.src"
                :style-object="baseImageStyleObject"
                @click="clickOnImage"
              />
            </div>
          </slot>
        </swiper-slide>

        <template #container-end>
          <slot name="navigation">
            <BaseCarouselArrow v-if="navigation" direction="prev" />
            <VirtualBaseCarouselPaginationV1 v-if="pagination" />
            <BaseCarouselArrow v-if="navigation" direction="next" />
          </slot>
        </template>
      </swiper>
    </nuxt-error-boundary>
  </div>
</template>
